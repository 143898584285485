import * as React from 'react'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { styled } from '../shared/theme'

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: space-around;
  justify-content: center;
  min-height: 100vh;
`

const Section = styled.section`
  position: relative;
  margin: auto;
`

const Title = styled.h1`
  position: relative;
  font-family: 'Kristi', cursive;
  font-size: 4em;
  font-weight: 400;
  line-height: 1;
  margin: 0.2em auto;
  text-align: center;
`

const Paragraph = styled.p`
  margin: 0 auto;
  font-style: italic;
  text-align: center;
  font-weight: 400;
`

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Container>
        <Section>
          <Title>Not found</Title>
          <Paragraph>
            You just hit a route that doesn&#39;t exist... the sadness.
          </Paragraph>
        </Section>
      </Container>
    </Layout>
  )
}
